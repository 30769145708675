﻿import React, {FC} from "react";
import NcImage from "components/NcImage/NcImage";
import {TaxonomyType} from "data/types";
import {NavLink} from "react-router-dom";

export interface CardCategory1Props {
    className?: string;
    taxonomy: TaxonomyType;
    size?: "large" | "normal";
}

const CardCategory1: FC<CardCategory1Props> = ({className = "", size = "normal", taxonomy}) => {
    const {count, name, href = "/", thumbnail} = taxonomy;
    return (
        <NavLink
            to={href}
            className={`nc-CardCategory1 flex items-center ${className}`}
            data-nc-id="CardCategory1"
        >
            <div>
                <h2
                    className={`${size === "large" ? "text-lg" : "text-base"
                    } nc-card-title text-neutral-900 dark:text-neutral-100 font-semibold`}
                >
                    {name}
                </h2>
            </div>
        </NavLink>
    );
};

export default CardCategory1;
