import React, {useEffect} from "react";
import {BrowserRouter, Switch, Route} from "react-router-dom";
import {Page} from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "components/Footer/Footer";
import Page404 from "containers/Page404/Page404";
import PageCategory from "containers/PageArchive/PageCategory";
import PageAuthor from "containers/PageAuthor/PageAuthor";
import PageSearch from "containers/PageSearch/PageSearch";
import PageSingleTemp3Sidebar from "containers/PageSingle/PageSingleTemp3Sidebar";
import PageAbout from "containers/PageAbout/PageAbout";
import PageContact from "containers/PageContact/PageContact";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageForgotPass from "containers/PageForgotPass/PageForgotPass";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import HeaderContainer from "containers/HeaderContainer/HeaderContainer";
import PageHomeMain from "containers/PageHome/PageHomeMain";
import PageSingleAudio from "containers/PageSingleAudio/PageSingleAudio";
import PageSingleVideo from "containers/PageSingleVideo/PageSingleVideo";
import MediaRunningContainer from "containers/MediaRunningContainer/MediaRunningContainer";
import PageLogout from "../containers/PageLogout/PageLogout";
import PagePages from "../containers/PagePages/PagePages";
import PageAuthorV2 from "../containers/PageAuthor/PageAuthorV2";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {getDinleBeniMainData, setDinleBeniMainData} from "../app/dinlebeni/dinlebeni";
import {db_get_homepage} from "../helper";

export const pages: Page[] = [
    {path: "/", exact: true, component: PageHomeMain},
    {path: "/#", exact: true, component: PageHomeMain},

    {path: "/kategori/:slug", component: PageCategory},
    {path: "/makale/:slug", component: PageSingleTemp3Sidebar},
    {path: "/dinle/:slug", component: PageSingleAudio,},
    {path: "/izle/:slug", component: PageSingleVideo,},
    {path: "/editor/:slug", component: PageAuthor},
    {path: "/arama", component: PageSearch},
    {path: "/iletisim", component: PageContact},
    {path: "/sayfa/:slug", component: PagePages},

    {path: "/uye/giris", component: PageLogin},
    {path: "/uye/cikis", component: PageLogout},
    {path: "/uye/uye-ol", component: PageSignUp},
    {path: "/uye/parolami-unuttum", component: PageForgotPass},
    {path: "/uye/sayfam", component: PageAuthorV2},
    {path: "/uye/abonelik", component: PageSubcription}
];

const Routes = () => {
    const dispatch = useAppDispatch();
    const dataMainPage = useAppSelector(getDinleBeniMainData);

    useEffect(() => {
        db_get_homepage()
            .then(response => response.json())
            .then(data => dispatch(setDinleBeniMainData(data)));
    }, []);

    return (
        <BrowserRouter basename={import.meta.env.VITE_LRT_OR_RTL === "rtl" ? "/" : "/"}>
            <MediaRunningContainer/>
            <HeaderContainer/>
            <Switch>
                {pages.map(({component, path, exact}) => {
                    return (
                        <Route
                            key={path}
                            component={component}
                            exact={!!exact}
                            path={path}
                        />
                    );
                })}
                <Route component={Page404}/>
            </Switch>
            <iframe id={"rk_ifrm"} style={{width: "1px", height: "1px", border: "none"}} frameBorder={"no"}
                    scrolling={"no"}></iframe>
            <img id={"rk_img"} style={{width: "1px", height: "1px"}}/>
            <Footer/>
            <ScrollToTop/>
        </BrowserRouter>
    );
};

export default Routes;
