﻿import React, {FC, useEffect, useState} from "react";
import ModalCategories from "./ModalCategories";
import {ApiViewArticleType, TaxonomyType} from "data/types";
import Pagination from "components/Pagination/Pagination";
import ArchiveFilterListBox from "components/ArchiveFilterListBox/ArchiveFilterListBox";
import {Helmet} from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import NcImage from "components/NcImage/NcImage";
import Card11 from "components/Card11/Card11";
import {api_uris} from "../../app/apiuri";
import {db_get_category_posts, db_get_homepage} from "../../helper";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {getDinleBeniMainData, setDinleBeniMainData} from "../../app/dinlebeni/dinlebeni";

export interface PageArchiveProps {
    className?: string;
}

const useAllData = (page: number) => {
    const [PAGE_DATA, setCategory] = useState<TaxonomyType>();
    const [CATEGORIES, setCategories] = useState<TaxonomyType[] | undefined>([]);
    const [posts, setPosts] = useState<ApiViewArticleType[]>();
    const [postCount, setPostCount] = useState<number>(0);
    const [url, setUrl] = useState(api_uris.single_category + location.pathname.replaceAll("/kategori/", ""));

    if (url !== api_uris.single_category + location.pathname.replaceAll("/kategori/", "")) {
        setUrl(api_uris.single_category + location.pathname.replaceAll("/kategori/", ""));
    }

    useEffect(() => {
        db_get_category_posts(url,{postCount:20,page:page})
            .then(response => response.json())
            .then(data => {
                setCategory(data.category);
                setPosts(data.posts);
                setCategories(data.categories);
                setPostCount(data.postCount);
            });
    }, [url,page]);

    return {PAGE_DATA, posts, CATEGORIES, postCount};
};

const PageCategory: FC<PageArchiveProps> = ({className = ""}) => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const dispatch = useAppDispatch();
    const dataMainPage = useAppSelector(getDinleBeniMainData);

    const {
        PAGE_DATA,
        posts,
        CATEGORIES,
        postCount
    } = useAllData(currentPage);

    useEffect(() => {
        const $body = document.querySelector("body");
        if ($body) {
            $body.className = "theme-fuchsia-blueGrey";
        }
        return () => {
            if ($body) {
                $body.className = "";
            }
        };
    }, []);

    useEffect(() => {
        db_get_homepage()
            .then(response => response.json())
            .then(data => dispatch(setDinleBeniMainData(data)));
    }, []);

    if (dataMainPage.latestPosts.length <= 0) {
        return (
            <div>
                Yükleniyor ...
            </div>);
    }


    if (!PAGE_DATA) {
        return <div>Yükleniyor ...</div>;
    }

    const FILTERS = [
        {name: "Son Yüklenenler", value: 1},
        {name: "En Çok Beğenilenler", value: 2},
        {name: "En Çok Yorumlananlar", value: 3},
        {name: "En Çok Görüntülenenler", value: 4},
    ];

    return (
        <div
            className={`nc-PageArchive overflow-hidden ${className}`}
            data-nc-id="PageArchive"
        >
            <Helmet>
                <title>{PAGE_DATA.name} | Dinle Beni</title>
            </Helmet>

            <div className="w-full px-2 xl:max-w-screen-2xl mx-auto">
                <div
                    className="rounded-3xl md:rounded-[40px] relative aspect-w-16 aspect-h-13 sm:aspect-h-9 lg:aspect-h-8 xl:aspect-h-5 overflow-hidden ">
                    <NcImage
                        containerClassName="absolute inset-0"
                        src={PAGE_DATA.thumbnail}
                        className="object-cover w-full h-full"
                    />
                    <div
                        className="absolute inset-0 bg-black text-white bg-opacity-30 flex flex-col items-center justify-center">
                        <h2 className="inline-block align-middle text-5xl font-semibold md:text-7xl ">
                            {PAGE_DATA.name}
                        </h2>
                        <span className="block mt-4 text-neutral-300">
                            {PAGE_DATA.count} Dinle Beni
                        </span>
                    </div>
                </div>
            </div>

            <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
                <div>
                    <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row">
                        <div className="flex space-x-2.5">
                            <ModalCategories categories={CATEGORIES}/>
                        </div>
                        <div className="block my-4 border-b w-full border-neutral-100 sm:hidden"></div>
                        <div className="flex justify-end">
                            <ArchiveFilterListBox lists={FILTERS}/>
                        </div>
                    </div>

                    {/* LOOP ITEMS */}
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
                        {posts?.map((post) => (
                            <Card11 key={post.id} post={post}/>
                        ))}
                    </div>

                </div>

                <Pagination total={postCount} current={1} pageSize={20} pageChanged={setCurrentPage}/>

                {/*<SectionSliderNewAuthors
                    heading="En İyi Editörler"
                    subHeading="En iyi editörlerimizin yayımlarını keşfedin."
                    authors={DEMO_AUTHORS.filter((_, i) => i < 10)}
                    uniqueSliderClass="PageArchive"
                />
                */}
                <SectionSubscribe2/>
            </div>
        </div>
    );
};

export default PageCategory;
