import WidgetHeading1 from "components/WidgetHeading1/WidgetHeading1";
import {ApiViewArticleType, TaxonomyType} from "data/types";
import React, {FC} from "react";
import ButtonPlayMusicRunningContainer
    from "../../containers/ButtonPlayMusicRunningContainer/ButtonPlayMusicRunningContainer";
import {Link} from "react-router-dom";

export interface WidgetCategoriesProps {
    className?: string;
    posts: ApiViewArticleType[] | undefined;
    title: string;
}

const WidgetPlaylist: FC<WidgetCategoriesProps> = (
    {className = "bg-neutral-100 dark:bg-neutral-800", posts, title = ""}) => {

    const renderIcon = (state?: "loading" | "playing") => {
        switch (state) {
            case "loading":
                return (
                    <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24">
                        <path
                            fill="currentColor"
                            d="M13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12Z"
                        ></path>
                        <path
                            fill="currentColor"
                            d="M9 12C9 12.5523 8.55228 13 8 13C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11C8.55228 11 9 11.4477 9 12Z"
                        ></path>
                        <path
                            fill="currentColor"
                            d="M17 12C17 12.5523 16.5523 13 16 13C15.4477 13 15 12.5523 15 12C15 11.4477 15.4477 11 16 11C16.5523 11 17 11.4477 17 12Z"
                        ></path>
                    </svg>
                );

            case "playing":
                return (
                    <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24">
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M15.25 6.75V17.25"
                        ></path>
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M8.75 6.75V17.25"
                        ></path>
                    </svg>
                );

            default:
                return (
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M18.25 12L5.75 5.75V18.25L18.25 12Z"
                        ></path>
                    </svg>
                );
        }
    };

    const renderDefaultBtnListen = (state?: "loading" | "playing") => {
        return (
            <div
                className="inline-flex items-center mt-3 pr-4 py-0.5 cursor-pointer rounded-full transition-all hover:pl-0.5 hover:bg-primary-50 dark:hover:bg-neutral-900">
                <span
                    className="w-8 h-8 flex items-center justify-center rounded-full bg-primary-50 dark:bg-neutral-800 text-primary-6000 dark:text-primary-200">
                    {renderIcon(state)}
                </span>
            </div>
        );
    };

    return (
        <div
            className={`nc-WidgetPlaylists rounded-3xl  overflow-hidden ${className}`}
            data-nc-id="WidgetPlaylists">

            <WidgetHeading1 title={title}/>

            <div className="flow-root">
                <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
                    {(posts !== undefined && posts.length > 0) && posts.map((post) => (
                        <div className="flex flex-row flex-grow ml-4 pb-4">
                            <Link to={post.href}>
                                <ButtonPlayMusicRunningContainer
                                    post={post}
                                    className=""
                                    renderDefaultBtn={() => renderDefaultBtnListen()}
                                    renderLoadingBtn={() => renderDefaultBtnListen("loading")}
                                    renderPlayingBtn={() => renderDefaultBtnListen("playing")}
                                ></ButtonPlayMusicRunningContainer>
                            </Link>
                            <h2 className={`nc-card-title block font-semibold text-lg pt-4`}>
                                <Link
                                    to={post.href}
                                    className={"line-clamp-1"}
                                    title={post.title}>
                                    {post.title}
                                </Link>
                            </h2>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default WidgetPlaylist;
