import bookmarkReducer from "./bookmarks/bookmarksSlice";
import postLikesReducer from "./postLikes/postLikes";
import commentLikesReducer from "./commentLikes/commentLikes";
import darkmodeReducer from "./darkmode/darkmode";
import pagesReducer from "./pages/pages";
import mediaRunningReducer from "./mediaRunning/mediaRunning";
import user from "./user";
import dinlebeni from "./dinlebeni/dinlebeni";

const rootReducers = {
    bookmark: bookmarkReducer,
    postLike: postLikesReducer,
    darkmode: darkmodeReducer,
    commentLikes: commentLikesReducer,
    pages: pagesReducer,
    mediaRunning: mediaRunningReducer,
    user: user,
    dinlebeni: dinlebeni
};

export default rootReducers;
