let mainapihost = 'https://dinlebeni.com.tr/api/db-rest/';

export const api_uris = {
    main_uri: `${mainapihost}main-page`,
    menu_uri: `${mainapihost}menus`,
    single_post: `${mainapihost}get-article`,
    single_page: `${mainapihost}get-page`,
    single_post_related: `${mainapihost}get-article-related`,
    single_category: `${mainapihost}get-category&category=`,
    login_uri: `${mainapihost}db-login`,
    logout_uri: `${mainapihost}db-logout`,
    singup_uri: `${mainapihost}db-signup`,
    single_post_viewed: `${mainapihost}db-postviewed`,
    single_post_play_started: `${mainapihost}db-postplaystarted`,
    single_post_play_ended: `${mainapihost}db-postplayended`,
    single_post_like: `${mainapihost}db-postlike`,
    single_post_bookmark: `${mainapihost}db-postbookmark`,
    liked_posts: `${mainapihost}db-get-liked-posts`,
    bookmarked_posts: `${mainapihost}db-get-bookmark-posts`,
}