import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "app/store";
import {MainPageDataType} from "../../data/types";


interface DinleBeniState {
    mainData: MainPageDataType;
}

const initialState: DinleBeniState = {
    mainData: {
        ads: [],
        podcasts: [],
        latestPosts: [],
        topCategories: [],
        playlists: [],
        topPosts: [],
        editorChoise: [],
        podcastLists: []
    }
};

export const dinlebeniSlicer = createSlice({
    name: "dinlebeni",
    initialState,
    reducers: {
        setDinleBeniMainData: (state, action: PayloadAction<MainPageDataType>) => {
            console.log("setDinleBeniMainData");
            state = {
                ...state,
                mainData: action.payload,
            };
            return state;
        },
    },
});

export const {setDinleBeniMainData} = dinlebeniSlicer.actions;

export const getDinleBeniMainData = (state: RootState) =>
    state.dinlebeni.mainData;

export default dinlebeniSlicer.reducer;
