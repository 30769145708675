﻿import ErrorBoundary from "components/ErrorBoundary";
import Input from "components/Input/Input";
import Logo from "components/Logo/Logo";
import MenuBar from "components/MenuBar/MenuBar";
import Navigation from "components/Navigation/Navigation";
import DarkModeContainer from "containers/DarkModeContainer/DarkModeContainer";
import {NAVIGATION_DEMO} from "data/navigation";
import React, {FC, useEffect, useState} from "react";
import AvatarDropdown from "./AvatarDropdown";
import NotifyDropdown from "./NotifyDropdown";
import {NavItemType} from "../Navigation/NavigationItem";
import {db_get_menus} from "../../helper";
import SearchDropdown from "./SearchDropdown";

export interface MainNav2LoggedProps {
}

const MainNav2Logged: FC<MainNav2LoggedProps> = () => {
    const [menuMain, setMenuMain] = useState<NavItemType[]>([]);
    const [menuFooter, setMenuFooter] = useState<NavItemType[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await db_get_menus();
                const json = await response.json();
                setMenuMain(json.mainMenu);
                setMenuFooter(json.footerMenu);
            } catch (error) {
                console.log("error", error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className={`nc-MainNav nc-MainNav2 relative z-10`}>
            <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
                <div className="flex justify-start flex-grow items-center space-x-3 sm:space-x-8 lg:space-x-10">
                    <Logo/>
                </div>
                <div
                    className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
                    <div className="hidden items-center xl:flex space-x-2">
                        <Navigation navigations={menuMain}/>
                        <div className="hidden sm:block h-6 border-l border-neutral-300 dark:border-neutral-6000"></div>
                        <div className="flex">
                            <SearchDropdown/>
                            <DarkModeContainer/>
                        </div>
                        <AvatarDropdown/>
                    </div>
                    <div className="flex items-center space-x-3 xl:hidden">
                        <AvatarDropdown/>
                        <ErrorBoundary>
                            <MenuBar/>
                        </ErrorBoundary>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainNav2Logged;
