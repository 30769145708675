import Avatar from "components/Avatar/Avatar";
import {ApiViewArticleType, PostAuthorType} from "data/types";
import React, {FC} from "react";
import {Link} from "react-router-dom";

export interface SingleAuthorProps {
    author: PostAuthorType;
    post?: ApiViewArticleType;
}

const SingleAuthor: FC<SingleAuthorProps> = ({author, post}) => {
    return (
        <div className="nc-SingleAuthor flex">
            <a href={author.href}>
                <Avatar
                    imgUrl={author.avatar}
                    userName={author.displayName}
                    sizeClass="h-12 w-12 text-lg sm:text-xl sm:h-24 sm:w-24 "
                    radius="rounded-xl"
                />
            </a>
            <div className="flex flex-col ml-3 max-w-lg sm:ml-5">
                <span className="text-xs text-neutral-400 uppercase tracking-wider">
                  Hazırlayan
                </span>
                <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
                    <a href={author.href} >{author.displayName}</a>
                </h2>
                {post != undefined && post!.originalArticleUrl != undefined &&
                    <p className={"italic"}>
                        <a href={post.originalArticleUrl} target={"_blank"}>Makalenin kaynağını görüntülemek için
                            tıklayınız.</a>
                        <br />
                    </p>
                }
                <br />
                <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
                    {author.desc}
                    <a className="text-primary-6000 font-medium ml-1" href={author.href}>
                        Editör Hakkında
                    </a>
                </span>
            </div>
        </div>
    );
};

export default SingleAuthor;
