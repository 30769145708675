import React, {FC} from "react";
import LayoutPage from "components/LayoutPage/LayoutPage";
import Input from "components/Input/Input";
import ButtonPrimary from "components/Button/ButtonPrimary";
import NcLink from "components/NcLink/NcLink";
import {Helmet} from "react-helmet";

export interface PageForgotPassProps {
    className?: string;
}

const PageForgotPass: FC<PageForgotPassProps> = ({className = ""}) => {
    return (
        <div
            className={`nc-PageForgotPass ${className}`}
            data-nc-id="PageForgotPass"
        >
            <Helmet>
                <title>Parolamı Unuttum | Dinle Beni</title>
            </Helmet>
            <LayoutPage
                subHeading="Parolanızı E-Mail adresinize göndereceğiz."
                headingEmoji="🔐"
                heading="Parolamı Unuttum"
            >
                <div className="max-w-md mx-auto space-y-6">
                    <form className="grid grid-cols-1 gap-6" action="#" method="post">
                        <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">
                                E-Mail Adresiniz
                            </span>
                            <Input
                                    type="email"
                                    placeholder="email@adresi.com"
                                    className="mt-1"
                                />
                        </label>
                        <ButtonPrimary type="submit">Devam</ButtonPrimary>
                    </form>

                    <span className="block text-center text-neutral-700 dark:text-neutral-300">
                    Ya da {` `}
                        <NcLink to="/uye/giris">Giriş Yap</NcLink>
                        {` / `}
                        <NcLink to="/uye/uye-ol">Üye Ol</NcLink>
                    </span>
                </div>
            </LayoutPage>
        </div>
    );
};

export default PageForgotPass;
