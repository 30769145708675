import LayoutPage from "components/LayoutPage/LayoutPage";
import React, {FC, FormEvent, useEffect, useState} from "react";
import NcLink from "components/NcLink/NcLink";
import {Helmet} from "react-helmet";
import {useAppDispatch} from "../../app/hooks";
import {userLogoutAction} from "../../app/user";
import {Link} from "react-router-dom";
import {dbLogout} from "../../helper";

export interface PageLoginProps {
    className?: string;
}

async function logout() {
    const response = await dbLogout();

    if (response.ok) {
        return null;
    } else {
        return await response.json();
    }
}

const PageLogout: FC<PageLoginProps> = ({className = ""}) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        logout().then((data) => {
            if (data === null) {
                dispatch(userLogoutAction());
            }
        });
    });


    return (
        <div className={`nc-PageLogin ${className}`} data-nc-id="Pagelogout">
            <Helmet>
                <title>Oturum Kapatıldı | Dinle Beni</title>
            </Helmet>

            <LayoutPage
                subHeading="Oturum başarıyla kapatıldı."
                headingEmoji="🔑"
                heading="Çıkış Yapıldı"
            >
                <div className="max-w-md mx-auto space-y-6">
                    Tekrar giriş yapmak isterseniz <NcLink to={"/uye/giris"}>buraya tıklayabilirsiniz.</NcLink>
                    {/* ==== */}
                    <span className="block text-center text-neutral-700 dark:text-neutral-300">
                        Yeni kullanıcı mısınız? {` `}
                        <NcLink to="/uye/uye-ol">Üye Ol</NcLink>
                    </span>
                </div>
            </LayoutPage>
        </div>
    );
};

export default PageLogout;
