import React, {FC, useEffect, useState} from "react";
import {ApiViewArticleType} from "data/types";
import {useAppDispatch, useAppSelector} from "app/hooks";
import {Helmet} from "react-helmet";
import SingleContent from "containers/PageSingle/SingleContent";
import SingleRelatedPosts from "containers/PageSingle/SingleRelatedPosts";
import SingleTitle from "containers/PageSingle/SingleTitle";
import SingleMetaAction2 from "containers/PageSingle/SingleMetaAction2";
import ButtonPlayMusicRunningContainer
    from "containers/ButtonPlayMusicRunningContainer/ButtonPlayMusicRunningContainer";
import LoadingVideo from "components/LoadingVideo/LoadingVideo";
import iconPlaying from "images/icon-playing.gif";
import {MediaRunningState} from "app/mediaRunning/mediaRunning";
import {api_uris} from "../../app/apiuri";
import {db_get_homepage, db_post_statistic_viewed} from "../../helper";
import WidgetCategories from "../../components/WidgetCategories/WidgetCategories";
import WidgetPlaylist from "../../components/WidgetPlaylist/WidgetPlaylist";
import {getDinleBeniMainData, setDinleBeniMainData} from "../../app/dinlebeni/dinlebeni";
import {useParams} from "react-router-dom";

export interface PageSingleAudioProps {
    className?: string;
}

const useAllData = () => {
    // @ts-ignore
    const {slug} = useParams();
    const [url, setUrl] = useState(api_uris.single_post + "&page=" + slug);
    const [article, setArticle] = useState<ApiViewArticleType>();

    if (url !== api_uris.single_post + "&page=" + slug) {
        setUrl(api_uris.single_post + "&page=" + slug);
    }

    useEffect(() => {
        fetch(url)
            .then(response => response.json())
            .then(data => setArticle(data));
    }, [url]);

    return {article};
};

const PageSingleAudio: FC<PageSingleAudioProps> = ({className = ""}) => {
    const {article} = useAllData();
    const dispatch = useAppDispatch();
    const dataMainPage = useAppSelector(getDinleBeniMainData);

    useEffect(() => {
        const $body = document.querySelector("body");
        if ($body) {
            $body.className = "theme-fuchsia-blueGrey";
        }
        return () => {
            if ($body) {
                $body.className = "";
            }
        };
    }, []);

    useEffect(() => {
        db_get_homepage()
            .then(response => response.json())
            .then(data => dispatch(setDinleBeniMainData(data)));
    }, []);

    if (dataMainPage.latestPosts.length <= 0) {
        return (
            <div>
                Yükleniyor ...
            </div>);
    }

    if (!article) {
        return <div>Yükleniyor ...</div>;
    } else {
        if (article.id !== '') {
            db_post_statistic_viewed(article.id);
        }
    }

    const renderIcon = (state?: MediaRunningState["state"]) => {
        if (state === "playing") {
            return <img className="w-7" src={iconPlaying} alt=""/>;
        }

        if (state === "loading") {
            return <LoadingVideo className="transform scale-75"/>;
        }
        return (
            <svg className="w-11 h-11" fill="currentColor" viewBox="0 0 24 24">
                <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M18.25 12L5.75 5.75V18.25L18.25 12Z"
                ></path>
            </svg>
        );
    };

    const playLists = article.playlists?.map((pl) => {
        return dataMainPage.playlists.filter((mpl) => mpl.id === pl.id).map((post) => (post.posts));
    });

    const renderButtonPlay = (isCurrentRunning: boolean, state?: MediaRunningState["state"]) => {
        let newState = state;
        if (!isCurrentRunning) {
            newState = null;
        }

        return (
            <div className={`aspect-w-1 aspect-h-1 rounded-full overflow-hidden shadow-2xl group cursor-pointer `}>
                <img
                    className={`w-full h-full object-cover transition-transform z-0 nc-animation-spin rounded-full ${newState === "playing" ? "playing" : ""
                    }`}
                    src={article.featuredImage}
                    alt="audio"
                />

                <div className="bg-neutral-900 bg-blend-multiply bg-opacity-75 rounded-full"></div>
                <div className="flex items-center justify-center">
                    <div
                        className="text-white bg-black bg-blend-multiply bg-opacity-50 w-20 h-20 border-2 border-neutral-300 rounded-full flex items-center justify-center ">
                        {renderIcon(newState)}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <div className={`nc-PageSingleAudio relative pt-8 lg:pt-16 ${className}`} data-nc-id="PageSingleAudio">
                {/* Overlay */}
                <div className="bg-primary-50 dark:bg-neutral-800 absolute top-0 inset-x-0 h-60 w-full"></div>
                <Helmet>
                    <title>{article.title}</title>
                </Helmet>

                <header className="relative container ">
                    <div
                        className="bg-white dark:bg-neutral-900 shadow-2xl px-5 py-7 md:p-11 rounded-2xl md:rounded-[40px] flex flex-col sm:flex-row items-center justify-center space-y-10 sm:space-y-0 sm:space-x-11">
                        <div className="w-1/2 sm:w-1/4 flex-shrink-0">
                            <ButtonPlayMusicRunningContainer
                                renderChildren={renderButtonPlay}
                                post={article}
                            />
                        </div>
                        <div className="flex flex-col space-y-5">
                            {/*
                            PODCAST YADA OYNATMA LİSTESİ İÇİN:
                            <div
                                className="flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0 sm:space-x-4">
                                <div>
                                    <Badge name="S1 EP. 128"/>
                                </div>
                                <span className="text-neutral-500 dark:text-neutral-400">
                                    Adventures in DevOps
                                    <span className="mx-2">·</span>
                                    Jul 22
                                </span>
                            </div>
                            */}

                            <SingleTitle title={article.title}/>

                            <span className="hidden lg:block text-lg text-neutral-500 dark:text-neutral-400">
                                {article.desc}
                            </span>

                            <SingleMetaAction2 meta={article}/>
                        </div>
                    </div>
                </header>

                <div className="container flex flex-col my-10 lg:flex-row ">
                    <div className={"w-full lg:w-3/5 xl:w-2/3 xl:pr-20"}>
                        <div className={"relative"}>
                            <SingleContent data={article}/>
                        </div>
                    </div>
                    <div className={"w-full mt-12 lg:mt-0 lg:w-2/5 lg:pl-10 xl:pl-0 xl:w-1/3"}>
                        {playLists.length > 0 &&
                            <WidgetPlaylist posts={playLists[0][0]} title={"Oynatma Listesi"}
                                            className={"bg-neutral-100 dark:bg-neutral-800 mb-5"}/>
                        }
                        <WidgetCategories
                            categories={dataMainPage?.topCategories}
                            title={"Kategoriler"}
                            description={"Popüler Kategoriler"}/>

                    </div>
                </div>

                <SingleRelatedPosts currentArticle={article}/>
            </div>
        </>
    );
};

export default PageSingleAudio;
