﻿import React, {FC, useEffect, useState} from "react";
import Logo from "components/Logo/Logo";
import Navigation from "components/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "components/Button/ButtonPrimary";
import MenuBar from "components/MenuBar/MenuBar";
import DarkModeContainer from "containers/DarkModeContainer/DarkModeContainer";
import ErrorBoundary from "components/ErrorBoundary";
import {NavItemType} from "../Navigation/NavigationItem";
import {db_get_menus} from "../../helper";

export interface MainNav1Props {
    isTop?: boolean;
}

const MainNav1: FC<MainNav1Props> = ({isTop}) => {
    const [menuMain, setMenuMain] = useState<NavItemType[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await  db_get_menus();
                const json = await response.json();
                setMenuMain(json.mainMenu);
            } catch (error) {
                console.log("error", error);
            }
        };

        fetchData();
    },[]);

    return (
        <div className={`nc-MainNav nc-MainNav1 relative z-10`}>
            <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
                <div className="flex justify-start flex-grow items-center space-x-4 sm:space-x-10 2xl:space-x-14">
                    <Logo/>
                    <Navigation navigations={menuMain}/>
                </div>
                <div
                    className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
                    <div className="hidden items-center xl:flex space-x-1">
                        <SearchDropdown/>
                        <DarkModeContainer/>
                        <div className="px-1"/>
                        <ButtonPrimary href="/uye/giris">Giriş</ButtonPrimary>
                    </div>
                    <div className="flex items-center xl:hidden">
                        <ButtonPrimary href="/uye/giris">Giriş</ButtonPrimary>
                        <div className="px-1"/>
                        <ErrorBoundary>
                            <MenuBar/>
                        </ErrorBoundary>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainNav1;
