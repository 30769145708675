import React, {FC, useEffect, useState} from "react";
import {DEMO_POSTS} from "data/posts";
import {PostAuthorType, ApiViewArticleType} from "data/types";
import Pagination from "components/Pagination/Pagination";
import ButtonPrimary from "components/Button/ButtonPrimary";
import {DEMO_AUTHORS} from "data/authors";
import Nav from "components/Nav/Nav";
import NavItem from "components/NavItem/NavItem";
import Avatar from "components/Avatar/Avatar";
import SocialsList from "components/SocialsList/SocialsList";
import ArchiveFilterListBox from "components/ArchiveFilterListBox/ArchiveFilterListBox";
import HeadBackgroundCommon from "components/HeadBackgroundCommon/HeadBackgroundCommon";
import {Helmet} from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import Card11 from "components/Card11/Card11";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import {DEMO_CATEGORIES} from "data/taxonomies";
import ButtonSecondary from "components/Button/ButtonSecondary";
import SectionSliderNewAuthors from "components/SectionSliderNewAthors/SectionSliderNewAuthors";
import {useAppSelector} from "../../app/hooks";
import {getUserAdmin, getUserAvatar, getUserNameSurname} from "../../app/user";
import {db_get_bookmarked_posts, db_get_liked_posts} from "../../helper";
import {data} from "autoprefixer";

export interface PageAuthorV2Props {
    className?: string;
}

const posts: ApiViewArticleType[] = DEMO_POSTS.filter((_, i) => i < 12);

const TABS = ["Beğendiklerim", "Kaydettiklerim", "Profilim"];

const PageAuthorV2: FC<PageAuthorV2Props> = ({className = ""}) => {
    const [tabActive, setTabActive] = useState<string>(TABS[0]);
    const [likedPosts, setLikedPosts] = useState<ApiViewArticleType[]>([]);
    const [bookmarkedPosts, setBookmarkedPosts] = useState<ApiViewArticleType[]>([]);
    const [currentPosts, setCurrentPosts] = useState<ApiViewArticleType[]>([]);
    const [pagedPosts, setPagedPosts] = useState<ApiViewArticleType[]>([]);

    useEffect(() => {
        db_get_liked_posts().then((res) => res.json()).then((data) => {
            setLikedPosts(data);
            setCurrentPosts(data);
            setPagedPosts(data.slice(0, 20));
        });

        db_get_bookmarked_posts().then((res) => res.json()).then((data) => {
            setBookmarkedPosts(data);
        });
    }, []);

    const userNameSurname = useAppSelector(getUserNameSurname);
    const userAvatar = useAppSelector(getUserAvatar);

    const handleClickTab = (item: string) => {
        if (item === tabActive) {
            return;
        }
        setTabActive(item);
        if (item === TABS[0]) {
            setCurrentPosts(likedPosts);
            setPagedPosts(likedPosts.slice(0, 20));
        } else if (item === TABS[1]) {
            setCurrentPosts(bookmarkedPosts);
            setPagedPosts(bookmarkedPosts.slice(0, 20));
        }
    };

    function changePage(page: number) {
        setPagedPosts(currentPosts.slice((page - 1) * 20, page * 20));
    }

    return (
        <div className={`nc-PageAuthorV2  ${className}`} data-nc-id="PageAuthorV2">
            <Helmet>
                <title>Profilim | Dinle Beni</title>
            </Helmet>
            <HeadBackgroundCommon className="h-24 2xl:h-28"/>
            <div className="container">
                <header
                    className="max-w-xl mx-auto -mt-10 flex flex-col items-center justify-center text-center lg:-mt-14">
                    <Avatar
                        containerClassName="ring-4 ring-white dark:ring-0 shadow-2xl"
                        imgUrl={userAvatar}
                        sizeClass="w-20 h-20 text-lg lg:w-28 lg:h-28 lg:text-xl"
                        radius="rounded-3xl"
                    />
                    <h2 className="block align-middle mt-4 font-semibold text-2xl text-neutral-900 lg:text-3xl dark:text-neutral-100">
                        {userNameSurname}
                    </h2>
                </header>
            </div>

            <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
                <main>
                    {/* TABS FILTER */}
                    <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row">
                        <Nav className="sm:space-x-2">
                            {TABS.map((item, index) => (
                                <NavItem key={index} isActive={tabActive === item} onClick={() => handleClickTab(item)}>
                                    {item}
                                </NavItem>
                            ))}
                        </Nav>
                        <div className="block my-4 border-b w-full border-neutral-100 sm:hidden"></div>
                    </div>

                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
                        {pagedPosts.map((post) => (
                            <Card11 key={post.id} post={post}/>
                        ))}
                    </div>

                    <div
                        className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
                        <Pagination total={currentPosts.length} pageSize={20} current={1} pageChanged={changePage}/>
                    </div>
                </main>

                <SectionSubscribe2/>
            </div>
        </div>
    );
};

export default PageAuthorV2;
