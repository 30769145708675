import React, {FC, useState} from "react";
import {DEMO_POSTS} from "data/posts";
import {PostAuthorType, ApiViewArticleType} from "data/types";
import Pagination from "components/Pagination/Pagination";
import ButtonPrimary from "components/Button/ButtonPrimary";
import {DEMO_AUTHORS} from "data/authors";
import Nav from "components/Nav/Nav";
import NavItem from "components/NavItem/NavItem";
import Avatar from "components/Avatar/Avatar";
import SocialsList from "components/SocialsList/SocialsList";
import ArchiveFilterListBox from "components/ArchiveFilterListBox/ArchiveFilterListBox";
import {Helmet} from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import Card11 from "components/Card11/Card11";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import {DEMO_CATEGORIES} from "data/taxonomies";
import ButtonSecondary from "components/Button/ButtonSecondary";
import SectionSliderNewAuthors from "components/SectionSliderNewAthors/SectionSliderNewAuthors";
import NcImage from "components/NcImage/NcImage";
import {useParams} from "react-router-dom";

export interface PageAuthorProps {
    className?: string;
}

const PageAuthor: FC<PageAuthorProps> = ({className = ""}) => {
    // @ts-ignore
    const {slug} = useParams();

    return (
        <div className={`nc-PageAuthor  ${className}`} data-nc-id="PageAuthor">
            <Helmet>
                <title>Editör || Dinle Beni</title>
            </Helmet>

            {/* HEADER */}
            <div className="w-screen px-2 xl:max-w-screen-2xl mx-auto">
                <div
                    className="rounded-3xl md:rounded-[40px] relative aspect-w-16 aspect-h-12 sm:aspect-h-7 xl:sm:aspect-h-6 overflow-hidden ">
                    <NcImage
                        containerClassName="absolute inset-0"
                        src="https://images.pexels.com/photos/459225/pexels-photo-459225.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                        className="object-cover w-full h-full"
                    />
                </div>
                <div className="relative container -mt-20 lg:-mt-48">
                    <div
                        className=" bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-16 rounded-[40px] shadow-2xl flex flex-col sm:flex-row sm:items-center">
                        <Avatar
                            containerClassName="ring-4 ring-white dark:ring-0 shadow-2xl"
                            imgUrl={""}
                            sizeClass="w-20 h-20 text-xl lg:text-2xl lg:w-36 lg:h-36"
                            radius="rounded-full"
                        />
                        <div className="mt-5 sm:mt-0 sm:ml-8 space-y-4 max-w-lg">
                            <h2 className="inline-block text-2xl sm:text-3xl md:text-4xl font-semibold">
                                {"Yazar Adı"}
                            </h2>
                            <span className="block text-sm text-neutral-6000 dark:text-neutral-300 md:text-base">
                {"Yazar Hakkında"}
              </span>
                            <SocialsList/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
                <main>
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
                        {/*posts.map((post) => (
                            <Card11 key={post.id} post={post}/>
                        ))*/}
                    </div>

                    {/* PAGINATION */}
                    <div
                        className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
                        <Pagination/>
                    </div>
                </main>

                {/* SUBCRIBES */}
                <SectionSubscribe2/>
            </div>
        </div>
    );
};

export default PageAuthor;
