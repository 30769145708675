﻿import Logo from "components/Logo/Logo";
import SocialsList1 from "components/SocialsList1/SocialsList1";
import {CustomLink} from "data/types";
import React, {useEffect, useState} from "react";
import {NavItemType} from "../Navigation/NavigationItem";
import {db_get_menus} from "../../helper";
import {Link} from "react-router-dom";

export interface WidgetFooterMenu {
    id: string;
    title: string;
    menus: CustomLink[];
}


const Footer: React.FC = () => {
    const [menuFooter, setMenuFooter] = useState<NavItemType[]>([]);
    const [widgetMenus, setWidgetMenus] = useState<WidgetFooterMenu[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await db_get_menus();
                const json = await response.json();
                setMenuFooter(json.footerMenu);

                let widgetMenus: WidgetFooterMenu[] = [];
                for (let i = 0; i < json.footerMenu.length; i++) {
                    const menu = json.footerMenu[i];
                    let wMenu: WidgetFooterMenu = {
                        id: menu.id,
                        title: menu.name,
                        menus: []
                    }
                    for (let j = 0; j < menu.children.length; j++) {
                        const child = menu.children[j];
                        wMenu.menus.push({
                            label: child.name,
                            href: child.href,
                            page: j,
                            navType: child.navType
                        });
                    }

                    widgetMenus.push(wMenu);
                }
                setWidgetMenus(widgetMenus);
            } catch (error) {
                console.log("error", error);
            }
        };

        fetchData();
    }, []);

    const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
        return (
            <div key={index} className="text-sm">
                <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
                    {menu.title}
                </h2>
                <ul className="mt-5 space-y-4">
                    {menu.menus.map((item, index) => (
                        <li key={index}>
                            {item.navType === "custom" ?
                                <a key={index} href={item.href}
                                   className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white">
                                    {item.label}
                                </a>
                                :
                                <Link key={index} to={item.href}
                                      className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white">
                                    {item.label}
                                </Link>
                            }
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    if (!menuFooter) {
        return (
            <div>
                Yükleniyor ...
            </div>);
    }

    return (
        <div className="nc-Footer relative py-4 lg:py-4 border-t border-neutral-200 dark:border-neutral-700">
            <div
                className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
                <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
                    <div className="col-span-2 md:col-span-1">
                        <Logo/>
                    </div>
                    <div className="col-span-2 flex items-center md:col-span-3">
                        <SocialsList1
                            className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start"/>
                    </div>
                </div>
                {widgetMenus.map(renderWidgetMenuItem)}
            </div>

            <div className={"text-center pt-5"}>
                <p className="text-sm text-neutral-6000 dark:text-neutral-300">
                    Group Medya A.Ş. © {(new Date()).getFullYear()} Tüm hakları saklıdır.
                </p>
            </div>
        </div>
    );
};

export default Footer;
