import React, {FormEvent, useEffect, useState} from "react";
import {dbCall} from "../../helper";
import {api_uris} from "../../app/apiuri";
import {userLoginAction, UserState} from "../../app/user";
import Input from "../Input/Input";
import NcLink from "../NcLink/NcLink";
import ButtonPrimary from "../Button/ButtonPrimary";
import {useAppDispatch} from "../../app/hooks";
import Checkbox from "../Checkbox/Checkbox";

export function LoginForm() {
    const [errorMessages, setErrorMessages] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const dispatch = useAppDispatch();

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let form = e.currentTarget;
        let data = new FormData(form);

        const username = data.get('username');
        const password = data.get('password');
        const remember_me = data.get('remember_me');

        if (username && password) {
            let header = new Headers();
            const response = await dbCall(api_uris.login_uri, 'post', {"username":username.toString(), "password":password.toString(),"remember_me":remember_me}, header);

            response.json().then((data) => {
                if (data.success === true) {
                    const payload: UserState = {
                        username: username.toString(),
                        name: data.name,
                        isLogged: true,
                        avatar: data.avatar,
                        isAdmin: data.isAdmin,
                    };

                    dispatch(userLoginAction(payload));
                    setIsSubmitted(true);
                    setErrorMessages('');
                    setTimeout(() => {
                        window.location.href = '/';
                    }, 2000);
                } else {
                    setErrorMessages(data.message);
                    setIsSubmitted(false);
                }
            });
        }

        return false;
    }

    return (
        <form className="grid grid-cols-1 gap-6" action="/db-login" method="post" onSubmit={handleSubmit}>
            <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                    Email Adresiniz:
                </span>
                <Input type="email" className="mt-1" name="username" id={"username"}/>
            </label>
            <label className="block">
                    <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                        Parolanız:
                        <NcLink to="/uye/parolami-unuttum" className="text-sm">
                            Parolamı Unuttum!
                        </NcLink>
                    </span>
                <Input type="password" className="mt-1" name="password" id={"password"} />
            </label>

            <Checkbox name={"remember_me"} label={"Beni Hatırla"} />

            <ButtonPrimary type="submit">Giriş</ButtonPrimary>

            {isSubmitted &&
                <NcLink className="text-green-500" to={"/"}>Giriş başarılı. Şimdi anasayfaya yönlendiriliyorsunuz. Yönlendirme çalışmazsa buraya tıklayınız.</NcLink>}
            {errorMessages && <div className="text-red-500">{errorMessages}</div>}
        </form>
    );
}