
import {DEMO_TAGS} from "./taxonomies";
import __comments from "./jsons/__comments.json";
import a10 from "./avatars/11.jpg";
import {DEMO_AUTHORS} from "./authors";
import {CommentType} from "components/CommentCard/CommentCard";
import {ApiViewArticleType,  } from "./types";

const nest = (
    items: CommentType[],
    id: number | null | undefined
): CommentType[] => {
    return items
        .filter((item) => item.parentId === id)
        .map((item) => ({
            ...item,
            children: nest(items, item.id as number | null),
        }));
};

// AUTHOR RANDOM FOR DEMO
const commentHasAuthor = __comments.map((item) => ({
    ...item,
    author: DEMO_AUTHORS[Math.floor(Math.random() * 10)],
}));

//
export const DEMO_COMMENTS = nest(commentHasAuthor, null);
export const SINGLE: ApiViewArticleType = {
    id: "",
    index: 0,
    categoriesId: [],
    description: "",
    imageUrl: "",
    originalArticleUrl: "",
    slug: "",
    articleListenDuration: 0,
    audioDuration: 0,
    editorName: "",
    editorSlug: "",
    magazineName: "",
    publishedDate: "",
    magazineUrl: "",
    articleTags: [],
    videoUrl: "",
    featuredImage: "",
    title: "",
    desc: "",
    date: "",
    href: "",
    commentCount: 0,
    viewdCount: 0,
    readingTime: 0,
    bookmark: {count: 0, isBookmarked: false},
    like: {count: 0, isLiked: false},
    author: {
        id: 0,
        firstName: "",
        lastName: "",
        displayName: "",
        email: "",
        avatar: "",
        count: 0,
        href: "",
        desc: "",
        jobName: "",
    },
    categories: [],
    postType: "audio",
    audioUrl: "",
    tags: [],
    content: "",
    comments: [],
    adTitle:"",
    adPicture:"",
    adRedirectUrl:"",
    adIframeUrl:"",
    adAudioUrl:"",
    adImageUrl:"",

    adActive: false,
    adDisableGlobal: false,

    podcast: false,

    playlists:[],

    podcastChild: false,
    podcastIndex: -1,
};

export const SINGLE_AUDIO: ApiViewArticleType = {
    id: "",
    index: 0,
    categoriesId: [],
    description: "",
    imageUrl: "",
    originalArticleUrl: "",
    slug: "",
    articleListenDuration: 0,
    audioDuration: 0,
    editorName: "",
    editorSlug: "",
    magazineName: "",
    publishedDate: "",
    magazineUrl: "",
    articleTags: [],
    videoUrl: "",
    featuredImage: "",
    title: "",
    desc: "",
    date: "",
    href: "",
    commentCount: 0,
    viewdCount: 0,
    readingTime: 0,
    bookmark: {count: 0, isBookmarked: false},
    like: {count: 0, isLiked: false},
    author: {
        id: 0,
        firstName: "",
        lastName: "",
        displayName: "",
        email: "",
        avatar: "",
        count: 0,
        href: "",
        desc: "",
        jobName: "",
    },
    categories: [],
    postType: "audio",
    audioUrl: "",
    tags: [],
    content: "",
    comments: [],
    adTitle:"",
    adPicture:"",
    adRedirectUrl:"",
    adIframeUrl:"",
    adAudioUrl:"",
    adImageUrl:"",

    adActive: false,
    adDisableGlobal: false,

    playlists:[],

    podcast: false,
    podcastChild: false,
    podcastIndex: -1,
};

export const SINGLE_VIDEO: ApiViewArticleType = {
    id: "ea21ac32ds-6c192f68dscx7e0c212",
    index: 1,
    categoriesId: [1],
    description: "",
    imageUrl: "",
    originalArticleUrl: "",
    slug: "",
    articleListenDuration: 0,
    audioDuration: 0,
    editorName: "",
    editorSlug: "",
    magazineName: "",
    publishedDate: "",
    magazineUrl: "",
    articleTags: [],
    audioUrl: "",
    featuredImage:
        "https://images.pexels.com/photos/326900/pexels-photo-326900.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    title: "Julio Urías does it all as Dodgers sweep in San Francisco",
    desc: "We’re an online magazine dedicated to covering the best in international product design. We started as a little blog back in 2002 covering student work and over time",
    date: "May 20, 2021",
    href: "/single/this-is-single-slug",
    commentCount: 14,
    viewdCount: 2378,
    readingTime: 6,
    bookmark: {count: 3502, isBookmarked: false},
    like: {count: 773, isLiked: true},
    author: {
        id: 10,
        firstName: "Mimi",
        lastName: "Fones",
        displayName: "Fones Mimi",
        email: "mfones9@canalblog.com",
        avatar: a10,
        count: 38,
        href: "/author/the-demo-author-slug",
        desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
        jobName: "Author Job",
    },
    categories: [
        {
            id: 2,
            name: "Jewelry",
            href: "/archive/the-demo-archive-slug",
            thumbnail:
                "https://images.unsplash.com/photo-1535632066927-ab7c9ab60908?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NjV8fGpld2Vscnl8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=60",
            count: 16,
            color: "red",
            taxonomy: "category",
        },
    ],
    postType: "video",
    videoUrl: "https://www.youtube.com/watch?v=a6roH6RffnA",
    tags: [DEMO_TAGS[0], DEMO_TAGS[1], DEMO_TAGS[3]],
    content: "",
    comments: DEMO_COMMENTS,
    adTitle:"",
    adPicture:"",
    adRedirectUrl:"",
    adIframeUrl:"",
    adAudioUrl:"",
    adImageUrl:"",

    adActive: false,
    adDisableGlobal: false,

    playlists:[],

    podcast: false,
    podcastChild: false,
    podcastIndex: -1,
};

export const SINGLE_GALLERY: ApiViewArticleType = {
    id: "eae0e85fd226c192f68dscx7e220c",
    index: 1,
    categoriesId: [1],
    description: "",
    imageUrl: "",
    originalArticleUrl: "",
    slug: "",
    articleListenDuration: 0,
    audioDuration: 0,
    editorName: "",
    editorSlug: "",
    magazineName: "",
    publishedDate: "",
    magazineUrl: "",
    articleTags: [],
    audioUrl: "",
    videoUrl: "",
    featuredImage:
        "https://images.pexels.com/photos/326900/pexels-photo-326900.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    title: "Julio Urías does it all as Dodgers sweep in San Francisco",
    desc: "We’re an online magazine dedicated to covering the best in international product design. We started as a little blog back in 2002 covering student work and over time",
    date: "May 20, 2021",
    href: "/single/this-is-single-slug",
    commentCount: 14,
    viewdCount: 2378,
    readingTime: 6,
    bookmark: {count: 3502, isBookmarked: false},
    like: {count: 773, isLiked: true},
    author: {
        id: 10,
        firstName: "Mimi",
        lastName: "Fones",
        displayName: "Fones Mimi",
        email: "mfones9@canalblog.com",
        avatar: a10,
        count: 38,
        href: "/author/the-demo-author-slug",
        desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
        jobName: "Author Job",
    },
    categories: [
        {
            id: 2,
            name: "Jewelry",
            href: "/archive/the-demo-archive-slug",
            thumbnail:
                "https://images.unsplash.com/photo-1535632066927-ab7c9ab60908?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NjV8fGpld2Vscnl8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=60",
            count: 16,
            color: "red",
            taxonomy: "category",
        },
    ],
    postType: "gallery",
    galleryImgs: [
        "https://images.pexels.com/photos/7354423/pexels-photo-7354423.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        "https://images.pexels.com/photos/7354413/pexels-photo-7354413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        "https://images.pexels.com/photos/7354417/pexels-photo-7354417.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        "https://images.pexels.com/photos/7354753/pexels-photo-7354753.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        "https://images.pexels.com/photos/7354665/pexels-photo-7354665.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    ],
    tags: [DEMO_TAGS[0], DEMO_TAGS[1], DEMO_TAGS[3]],
    content: "",
    comments: DEMO_COMMENTS,
    adTitle:"",
    adPicture:"",
    adRedirectUrl:"",
    adIframeUrl:"",
    adAudioUrl:"",
    adImageUrl:"",

    adActive: false,
    adDisableGlobal: false,

    playlists:[],

    podcast: false,
    podcastChild: false,
    podcastIndex: -1,
};
