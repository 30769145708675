import ButtonPrimary from "components/Button/ButtonPrimary";
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {api_uris} from "../../app/apiuri";
import {ApiPageViewType, ApiViewArticleType} from "../../data/types";

const useAllData = () => {
    const [url, setUrl] = useState(api_uris.single_page + "&page=" + location.pathname);
    const [page, setPage] = useState<ApiPageViewType>();

    if (url !== api_uris.single_page + "&page=" + location.pathname) {
        setUrl(api_uris.single_page + "&page=" + location.pathname);
    }

    useEffect(() => {
        fetch(url)
            .then(response => response.json())
            .then(data => setPage(data));
    }, [url]);

    return {page};
};
const PagePages: React.FC = () => {
    const {page} = useAllData();
    if (!page) {
        return (
            <div className="nc-PagePages">
                <div className="container relative py-16 lg:py-20">
                    Yükleniyor...
                </div>
            </div>
        )
    }
    return (
        <div className="nc-PagePages">
            <Helmet>
                <title>{page.title} | Dinle Beni</title>
            </Helmet>
            <div className="container relative py-16 lg:py-20">
                <React.Fragment>
                    {<div dangerouslySetInnerHTML={{ __html: page.content }} />}
                </React.Fragment>
            </div>
        </div>
    )
};

export default PagePages;
