﻿import React, { FC } from "react";

import Heading from "components/Heading/Heading";
import CardBoxArticle from "components/CardBoxArticle/CardBoxArticle";
import CardBoxArticleMini from "components/CardBoxArticle/CardBoxArticleMini";

import { ApiViewArticleType } from "data/types";

export interface LatestArticlesProps {
    className?: string;
    post: Array<ApiViewArticleType>;
    ratio?: string;
}

const LatestArticles: FC<LatestArticlesProps> = ({
    className = "h-full",
    post,
    ratio = "aspect-w-3 xl:aspect-w-4 aspect-h-3",
}) => {
    return (
        <div className="py-4 lg:py-6">
            <Heading>Son Makaleler</Heading>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
                <CardBoxArticle post={post[0]} />
                <CardBoxArticle post={post[1]} />
                <CardBoxArticle post={post[2]} />
                <div className="md:col-span-2 lg:col-span-3">
                    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
                        {post.slice(3).filter((_, i) => i < 11).map((p) => (
                            <CardBoxArticleMini key={p.id} post={p} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LatestArticles;