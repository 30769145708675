import React, {FC} from "react";
import Pagination from "components/Pagination/Pagination";
import Input from "components/Input/Input";
import {Helmet} from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import NcImage from "components/NcImage/NcImage";
import ButtonCircle from "components/Button/ButtonCircle";

export interface PageSearchProps {
    className?: string;
}

const PageSearch: FC<PageSearchProps> = ({className = ""}) => {
    let s = "";

    return (
        <div className={`nc-PageSearch ${className}`} data-nc-id="PageSearch">
            <Helmet>
                <title>Arama || Dinle Beni</title>
            </Helmet>

            {/* HEADER */}
            <div className="w-screen px-2 xl:max-w-screen-2xl mx-auto">
                <div
                    className="rounded-3xl md:rounded-[40px] relative aspect-w-16 aspect-h-16 sm:aspect-h-9 lg:aspect-h-5 overflow-hidden ">
                    <NcImage
                        containerClassName="absolute inset-0"
                        src="https://images.pexels.com/photos/2138922/pexels-photo-2138922.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                        className="object-cover w-full h-full"
                    />
                </div>
                {/* CONTENT */}
                <div className="relative container -mt-20 lg:-mt-48">
                    <div
                        className=" bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-16 rounded-[40px] shadow-2xl flex items-center">
                        <header className="w-full max-w-3xl mx-auto text-center flex flex-col items-center">
                            <h2 className="text-2xl sm:text-4xl font-semibold">{s}</h2>
                            {/*<span className="block text-xs sm:text-sm mt-4 text-neutral-500 dark:text-neutral-300">
                Sonuç{" "}
                                <strong className="font-medium text-neutral-800 dark:text-neutral-100">
                  1135
                </strong>{" "}
                                results for{" "}
                                <strong className="font-medium text-neutral-800 dark:text-neutral-100">
                  {s}
                </strong>
              </span>*/}
                            <form
                                className="relative w-full mt-8 sm:mt-11 text-left"
                                method="post"
                            >
                                <label
                                    htmlFor="search-input"
                                    className="text-neutral-500 dark:text-neutral-300"
                                >
                                    <span className="sr-only"></span>
                                    <Input
                                        id="search-input"
                                        type="search"
                                        placeholder="Arayacağınız kelimeyi giriniz ..."
                                        sizeClass="pl-14 py-5 pr-5 md:pl-16"
                                        defaultValue={s}
                                    />
                                    <ButtonCircle
                                        className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
                                        size=" w-11 h-11"
                                        type="submit"
                                    >
                                        <i className="las la-arrow-right text-xl"></i>
                                    </ButtonCircle>
                                    <span
                                        className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
                    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"
                      ></path>
                    </svg>
                  </span>
                                </label>
                            </form>
                        </header>
                    </div>
                </div>
            </div>
            {/* ====================== END HEADER ====================== */}

            <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
                <main>
                    {/* Sonuçlar burada çıkacak. */}

                    <div
                        className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
                        <Pagination/>
                        {/* <ButtonPrimary>Daha fazla göster</ButtonPrimary> */}

                    </div>
                </main>

                <SectionSubscribe2/>
            </div>
        </div>
    );
};

export default PageSearch;
