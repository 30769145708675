import Heading from "components/Heading/Heading";
import { PodcastType} from "data/types";
import React, {FC} from "react";
import Card15Podcast from "../../components/Card15Podcast/Card15Podcast";

export interface SectionPodcastProps {
    playlists?: PodcastType[];
    className?: string;
    title?: string;
    desc?: string;
}

const SectionPodcast: FC<SectionPodcastProps> = (
    { playlists = [], className = "", title = ""}) => {
    return (
        <div className={`nc-SectionPodcast relative ${className}`}>
            <Heading desc={""}>
                {title}
            </Heading>
            <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-8`}>
                {playlists.map((p) => (
                    <Card15Podcast
                        key={p.id}
                        post={p.podcasts[0]}
                        title={p.title}
                        description={`${p.podcasts.length} Podcast`}
                    />
                ))}
            </div>
        </div>
    );
};

export default SectionPodcast;
