import Card17Podcast from "components/Card17Podcast/Card17Podcast";
import Heading from "components/Heading/Heading";
import {PlaylistType} from "data/types";
import React, {FC} from "react";
import Card15Podcast from "../../components/Card15Podcast/Card15Podcast";
import Card9 from "../../components/Card9/Card9";

export interface SectionMagazine8Props {
    playlists?: PlaylistType[];
    className?: string;
    title?: string;
    desc?: string;
}

const SectionMagazine8: FC<SectionMagazine8Props> = (
    {playlists = [], className = "", title = "", desc = ""}) => {

    // @ts-ignore
    return (
        <div className={`nc-SectionMagazine9 relative ${className}`}>
            <Heading desc={""}>
                {title}
            </Heading>
            <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-8`}>
                {playlists.map((p) => (
                    <Card15Podcast key={p.id} post={p.posts![0]} title={p.name}
                                   description={`${p.posts!.length} Parça`}/>
                ))}
            </div>
        </div>
    );
};

export default SectionMagazine8;
