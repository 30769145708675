import React, {FC} from "react";
import LayoutPage from "components/LayoutPage/LayoutPage";
import NcLink from "components/NcLink/NcLink";
import {Helmet} from "react-helmet";
import {SignupForm} from "../../components/Forms/SignupForm";
import {SocialLogin} from "../../components/Forms/SocialLogin";

export interface PageSignUpProps {
    className?: string;
}

const PageSignUp: FC<PageSignUpProps> = ({className = ""}) => {
    return (
        <div className={`nc-PageSignUp ${className}`} data-nc-id="PageSignUp">
            <Helmet>
                <title>Üye Ol | Dinle Beni</title>
            </Helmet>
            <LayoutPage
                subHeading="Aramıza hoşgeldiniz. Bizlere katılmak için, lütfen aşağıdaki formu doldurun."
                headingEmoji="🎉"
                heading="Üye Ol"
            >
                <div className="max-w-md mx-auto space-y-6">
                    {/* <SocialLogin/> */}

                    <SignupForm/>

                    <span className="block text-center text-neutral-700 dark:text-neutral-300">
                        Hesabınız var mı? {` `}
                        <NcLink to="/uye/giris">Üye Girişi</NcLink>
                    </span>
                </div>
            </LayoutPage>
        </div>
    );
};

export default PageSignUp;
