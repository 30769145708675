import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import persistReducer from "redux-persist/es/persistReducer";
import {mediaRunningSlice} from "./mediaRunning/mediaRunning";
import storage from "redux-persist/lib/storage";

export interface UserState {
    username: string;
    isLogged: boolean;
    name?: string;
    avatar?: string;
    isAdmin?: boolean;
}

const initialState: UserState = {
    username: '',
    isLogged: false,
    name: ''
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        userLoginAction: (state, action: PayloadAction<UserState>) => {
            state = action.payload;
            return state;
        },
        userLogoutAction: (state) => {
            state = {
                username: '',
                isLogged: false,
                name: '',
                isAdmin: false,
                avatar: undefined
            };
            return state;
        }
    },
});
export const {userLoginAction, userLogoutAction} = userSlice.actions;

// @ts-ignore
export const getUsername = (state: RootState) => state.user.username;

export const getUserNameSurname = (state: RootState) => state.user.name;
export const getUserAvatar = (state: RootState) => state.user.avatar;
export const getUserAdmin = (state: RootState) => state.user.isAdmin;

// @ts-ignore
export const getIsLogged = (state: RootState) => state.user.isLogged;

const persistConfig = {
    key: "user",
    storage: storage
};

export default persistReducer(persistConfig, userSlice.reducer);