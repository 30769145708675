import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionMagazine9 from "./SectionMagazine9";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import LatestArticles from "components/LatestArticles/LatestArticles";

import {db_get_homepage} from "../../helper";
import SectionMagazine8 from "./SectionMagazine8";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {getDinleBeniMainData, setDinleBeniMainData} from "../../app/dinlebeni/dinlebeni";
import SectionPodcast from "./SectionPodcast";


const PageHomeMain: React.FC = () => {
    const dispatch = useAppDispatch();
    const dataMainPage = useAppSelector(getDinleBeniMainData);

    useEffect(() => {
        const $body = document.querySelector("body");
        if ($body) {
            $body.className = "theme-fuchsia-blueGrey";
        }
        return () => {
            if ($body) {
                $body.className = "";
            }
        };
    }, []);

    useEffect(() => {
        db_get_homepage()
            .then(response => response.json())
            .then(data => dispatch(setDinleBeniMainData(data)));
    }, []);

    if (dataMainPage.latestPosts.length <= 0) {
        return (
            <div>
                Yükleniyor ...
            </div>);
    }

    return (
        <div className="nc-PageHomeDemo3 overflow-hidden relative">
            <Helmet>
                <title>Dinle Beni</title>
            </Helmet>

            <BgGlassmorphism/>

            <div className="container relative">
                {/* === TOP REKLAM === */}
                {/*<SectionAds />*/}

                {/* === Son Makaleler === */}
                <LatestArticles post={dataMainPage.latestPosts}/>

                {/* BURAYI GÜNCELLE İLERİDE:
                <div className="relative py-2">
                    <SectionMagazine9
                        posts={dataMainPage.topPosts}
                    />
                </div> */}

                {dataMainPage.editorChoise.length > 0 && (
                    <div className="relative py-2">
                        <SectionMagazine9
                            posts={dataMainPage.editorChoise}
                            heading="Editörün Seçtikleri"
                            desc="Editörlerimiz tarafından seçilmiş makalelerimiz."
                        />
                    </div>
                )}

                {/* === Top Kategoriler === */}
                <div className="relative py-0">
                    <SectionGridCategoryBox
                        headingCenter={false}
                        categoryCardType="card2"
                        className="pb-4 lg:pb-6 mg-2"
                        categories={dataMainPage.topCategories}
                    />
                </div>

                {dataMainPage.playlists.length > 0 && dataMainPage.playlists[0].posts!.length > 0 && (
                    <SectionMagazine8
                        className="py-4 lg:py-4"
                        title="Editörün Oluşturduğu Listeler"
                        desc="Editörlerimiz tarafından seçilmiş oynatma listeleri."
                        playlists={dataMainPage.playlists}
                    />
                )}

                {dataMainPage.podcastLists!.length > 0 && (
                    <SectionPodcast
                        className="py-4 lg:py-4"
                        title="Podcast Listeleri"
                        desc="Sizler için hazırlanmış Podcast listeleri."
                        playlists={dataMainPage.podcastLists}
                    />
                )}

                <div className="relative py-0">
                    <SectionSubscribe2 className="pb-4 lg:pb-6"/>
                </div>

            </div>
        </div>
    );
};

export default PageHomeMain;
