import React, {FC} from "react";
import ButtonCircle from "components/Button/ButtonCircle";
import rightImg from "images/SVG-subcribe2.png";
import NcImage from "components/NcImage/NcImage";
import Badge from "components/Badge/Badge";
import Input from "components/Input/Input";
import Button from "../Button/Button";

export interface SectionSubscribe2Props {
    className?: string;
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({className = ""}) => {
    return (
        <div
            className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row items-center ${className}`}
            data-nc-id="SectionSubscribe2"
        >
            <div className="flex-shrink-0 mb-14 lg:mb-0 lg:mr-10 lg:w-2/5">
                <h2 className="font-semibold text-4xl">Bültenimize katılın 🎉</h2>
                <span className="block mt-6 text-neutral-500 dark:text-neutral-400">
                    Dinleyin ve hemen her konuda yeni bakış açıları paylaşın.
                </span>
                <form className="mt-10 relative max-w-sm" onSubmit={()=>{return false; }}>
                    <Input
                        required
                        aria-required
                        placeholder="Adınız ..."
                        type="text"
                        className={"mb-2"}
                    />
                    <Input
                        required
                        aria-required
                        placeholder="Soyadınız ..."
                        type="text"
                        className={"mb-2"}
                    />
                    <Input
                        required
                        aria-required
                        placeholder="Email adresiniz ..."
                        type="email"
                        className={"mb-2"}
                    />
                    <div className="flex items-center mt-4">
                        <Button type={"submit"}>Abone Ol</Button>
                    </div>
                </form>
            </div>
            <div className="flex-grow text-center">
                <NcImage src={rightImg} className={`h-96 align-center`}/>
            </div>
        </div>
    );
};

export default SectionSubscribe2;
