import {FormEvent} from "react";
import {RootState} from "./app/store";
import {api_uris} from "./app/apiuri";

/**
 * WordPress Security Check
 */
export function db_check_wp(): boolean {
    return false;
}

/**
 * Wordpress Cookie Check
 */
export function get_wp_nonce(): string {
    if (document.cookie.indexOf("dbauth=") > -1) {
        return document.cookie.split("dbauth=")[1].split(";")[0];
    } else {
        return '';
    }
}


export function dbCall(url: string, method = 'get', data?: any, headers?: Headers): Promise<Response> {
    const requestHeaders: HeadersInit = headers || new Headers();
    let wp_nonce = get_wp_nonce();

    if (wp_nonce !== '') {
        //requestHeaders.append('X-WP-Nonce', wp_nonce);
    }

    let requestInit: RequestInit = {
        method: method,
        headers: requestHeaders,
        credentials: 'include'
    };
    if (data) {
        if (method === 'get') {
            if (url.indexOf('?') > -1) {
                url = url + '&' + new URLSearchParams(data).toString();
            } else {
                url = url + '&' + new URLSearchParams(data).toString();
            }
        } else {
            let formData = new FormData();
            for (let key in data) {
                formData.append(key, data[key]);
            }
            requestInit.body = formData;
        }
    }

    return fetch(url, requestInit);
}

export function db_get_homepage(): Promise<Response> {
    return dbCall(api_uris.main_uri, 'get');
}

export function db_get_menus(): Promise<Response> {
    return dbCall(api_uris.menu_uri, 'get');
}

export function db_get_category_posts(url: any, data: any): Promise<Response> {
    return dbCall(url, 'get', data);
}

export function db_get_post_related(id: any): Promise<Response> {
    return dbCall(api_uris.single_post_related, 'get', {id: id});
}

export function db_get_liked_posts(): Promise<Response> {
    return dbCall(api_uris.liked_posts, 'get');
}

export function db_get_bookmarked_posts(): Promise<Response> {
    return dbCall(api_uris.bookmarked_posts, 'get');
}

export function db_post_statistic_viewed(id: any): void {
    dbCall(api_uris.single_post_viewed, 'post', {id: id}).then(r => {

    });
}

export function db_post_statistic_play_start(id: any): void {
    dbCall(api_uris.single_post_play_started, 'post', {id: id}).then(r => {

    });
}

export function db_post_statistic_play_end(id: any): void {
    dbCall(api_uris.single_post_play_ended, 'post', {"id": id}).then(r => {
    });
}

export function db_post_liked(id: any, liked: boolean): void {
    dbCall(api_uris.single_post_like, 'post', {id: id, liked: liked}).then(r => {

    });
}

export function db_post_bookmarked(id: any, bookmarked?: boolean): boolean {
    dbCall(api_uris.single_post_bookmark, 'post', {id: id, bookmarked: bookmarked}).then(r => {

    });
    return false;
}

export function dbLogout(): Promise<Response> {
    return dbCall(api_uris.logout_uri, 'get');
}
