import React, {FC, useEffect, useState} from "react";
import Heading from "components/Heading/Heading";
import {ApiViewArticleType} from "data/types";
import Card11 from "components/Card11/Card11";
import Card9 from "components/Card9/Card9";
import {DEMO_POSTS} from "data/posts";
import {api_uris} from "../../app/apiuri";
import {db_get_post_related} from "../../helper";

export interface SingleRelatedPostsProps {
    currentArticle?: ApiViewArticleType;
}

export interface RelatedPostAndEditorPicks {
    relatedPosts: ApiViewArticleType[];
    editorPicks: ApiViewArticleType[];
}

const getRelatedArticles = (article?: ApiViewArticleType) => {
    if (article?.id === '' || article?.id === undefined) {
        return undefined;
    }

    const [url, setUrl] = useState(api_uris.single_post + "&page=" + location.pathname);
    const [relatedPostsAndEditorPicks, setRelatedPosts] = useState<RelatedPostAndEditorPicks>();

    if(url !== api_uris.single_post + "&page=" + location.pathname){
        setUrl(api_uris.single_post + "&page=" + location.pathname);
    }

    useEffect(() => {
        db_get_post_related(article?.id)
            .then(response => response.json())
            .then(data => setRelatedPosts(data));
    }, [url]);

    return {relatedPostsAndEditorPicks};
};

const SingleRelatedPosts: FC<SingleRelatedPostsProps> = ({currentArticle = undefined}) => {
    const {relatedPostsAndEditorPicks} = getRelatedArticles(currentArticle) || {};
    if (!relatedPostsAndEditorPicks) {
        return <div>Yükleniyor ...</div>;
    }
    else{
        if(relatedPostsAndEditorPicks.relatedPosts.length === 0 && relatedPostsAndEditorPicks.editorPicks.length === 0){
            return <div></div>;
        }
    }

    return (
        <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-28">
            <div className="container">
                <div>
                    <Heading
                        className="mb-10 text-neutral-900 dark:text-neutral-50"
                        desc=""
                    >
                        İlgili Yazılar
                    </Heading>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
                        {relatedPostsAndEditorPicks.relatedPosts.map((post) => (
                            <Card11 key={post.id} post={post}/>
                        ))}
                    </div>
                </div>

                <div className="mt-20">
                    <Heading
                        className="mb-10 text-neutral-900 dark:text-neutral-50"
                        desc=""
                    >
                        Editörden
                    </Heading>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
                        {relatedPostsAndEditorPicks.editorPicks.map((post) => (
                            <Card9 key={post.id} post={post}/>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SingleRelatedPosts;
