import React, {FC, useState} from "react";
import twFocusClass from "utils/twFocusClass";
import NcDropDown from "components/NcDropDown/NcDropDown";
import ModalReportItem from "components/ModalReportItem/ModalReportItem";
import {ApiViewArticleType} from "data/types";
import ModalHideAuthor from "./ModalHideAuthor";
import {useHistory} from "react-router";
import {useAppSelector} from "../../app/hooks";
import {getUserAdmin} from "../../app/user";

export interface PostActionDropdownProps {
    containerClassName?: string;
    iconClass?: string;
    postData: ApiViewArticleType;
    dropdownPositon?: "up" | "down";
}

const PostActionDropdown: FC<PostActionDropdownProps> = (
    {
        containerClassName = "h-8 w-8 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-700",
        iconClass = "h-[18px] w-[18px]",
        dropdownPositon = "down",
        postData,
    }) => {
    let actions = [
        {
            id: "copylink",
            name: "Linki Kopyala",
            icon: "las la-copy",
        },
        {
            id: "commentThisArticle",
            name: "Yorumlar",
            icon: "las la-comment-dots",
        },
        {
            id: "hideThisAuthor",
            name: "Editörü Gizle",
            icon: "las la-user-slash",
        },
        {
            id: "reportThisArticle",
            name: "Makaleyi Bildir",
            icon: "las la-flag",
        },
        {
            id: "editArticle",
            name: "Makaleyi Düzenle",
            icon: "las la-edit",
        },
    ];
    //
    let history = useHistory();
    //
    const [isReporting, setIsReporting] = useState(false);
    const [showModalHideAuthor, setShowModalHideAuthor] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const userAdmin = useAppSelector(getUserAdmin);
    if (!userAdmin) {
        //check if user is admin remove editArticle from actions
        actions = actions.filter((item) => item.id !== "editArticle");
    }

    const openModalReportPost = () => setIsReporting(true);
    const closeModalReportPost = () => setIsReporting(false);

    const openModalHideAuthor = () => setShowModalHideAuthor(true);
    const onCloseModalHideAuthor = () => setShowModalHideAuthor(false);

    const hanldeClickDropDown = (item: typeof actions[number]) => {
        if (item.id === "copylink") {
            navigator.clipboard.writeText(window.location.origin + postData.href);
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 1000);
            return;
        }
        if (item.id === "reportThisArticle") {
            return openModalReportPost();
        }
        if (item.id === "hideThisAuthor") {
            return openModalHideAuthor();
        }
        if (item.id === "commentThisArticle") {
            return history.push(postData.href + "#comment");
        }
        if (item.id === "editArticle") {
            window.open('https://dinlebeni.com.tr/wp-admin/post.php?post=' + postData.id + '&action=edit')
            return;
        }

        return;
    };

    const renderMenu = () => {
        if (isCopied) {
            actions = actions.map((item) => {
                if (item.id !== "copylink") return item;
                return {
                    ...item,
                    name: "Link Kopyalandı",
                };
            });
        }
        return (
            <NcDropDown
                className={`text-neutral-500 dark:text-neutral-400 flex items-center justify-center rounded-full  ${containerClassName} ${twFocusClass()}`}
                iconClass={iconClass}
                data={actions}
                panelMenusClass={
                    dropdownPositon === "up" ? "origin-bottom-right bottom-0" : undefined
                }
                onClick={hanldeClickDropDown}
            />
        );
    };

    return (
        <div>
            {renderMenu()}

            <ModalReportItem
                show={isReporting}
                id={postData.id}
                onCloseModalReportItem={closeModalReportPost}
            />
            <ModalHideAuthor
                show={showModalHideAuthor}
                auhthor={postData.author}
                onCloseModalHideAuthor={onCloseModalHideAuthor}
            />
        </div>
    );
};

export default PostActionDropdown;
