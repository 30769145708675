import {CustomLink} from "data/types";
import React, {FC} from "react";
import {Link} from "react-router-dom";
import twFocusClass from "utils/twFocusClass";

export interface PaginationProps {
    className?: string;
    total?: number;
    current?: number;
    pageSize?: number;
    pageChanged?: (page: number) => void;
}

const Pagination: FC<PaginationProps> = ({className = "", total = 0, pageSize = 0, pageChanged}) => {
    const totalPageLink = total / pageSize;
    const pageLinks: CustomLink[] = [];
    for (let i = 0; i <= totalPageLink; i++) {
        pageLinks.push({
            label: (i + 1).toString(),
            href: "#",
            page: i + 1
        });
    }

    const renderItem = (pag: CustomLink, index: number) => {
        if (pag.selected) {
            return (
                <span
                    className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white ${twFocusClass()}`}
                    key={index}>
                    {pag.label}
                </span>
            );
        }

        return (
            <Link
                key={index}
                className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
                to={pag.href} onClick={() => {
                if (pageChanged) {
                    pag.selected = true;
                    pageChanged(pag.page)
                }
            }}>
                {pag.label}
            </Link>
        );
    };

    return (
        <nav className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}>
            {pageLinks.map(renderItem)}
        </nav>
    );
};

export default Pagination;
