import LayoutPage from "components/LayoutPage/LayoutPage";
import React, {FC} from "react";
import NcLink from "components/NcLink/NcLink";
import {Helmet} from "react-helmet";
import {LoginForm} from "../../components/Forms/LoginForm";

export interface PageLoginProps {
    className?: string;
}

const PageLogin: FC<PageLoginProps> = ({className = ""}) => {

    return (
        <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
            <Helmet>
                <title>Giriş | Dinle Beni</title>
            </Helmet>

            <LayoutPage
                subHeading="Tekrar hoşgeldiniz. Giriş için aşağıdaki formu kullanınız."
                headingEmoji="🔑"
                heading="Giriş"
            >
                <div className="max-w-md mx-auto space-y-6">
                    {/* <SocialLogin/> */}

                    <LoginForm/>

                    <span className="block text-center text-neutral-700 dark:text-neutral-300">
                        Yeni kullanıcı mısınız? {` `}
                        <NcLink to="/uye/uye-ol">Üye Ol</NcLink>
                    </span>
                </div>
            </LayoutPage>
        </div>
    );
};

export default PageLogin;
