import React from "react";
import {Link} from "react-router-dom";
import logoDark from "images/logo-dark-n.png";
import logoLightImg from "images/logo-light-n.png";
import {useAppSelector} from "../../app/hooks";
import {selectDarkmodeState} from "../../app/darkmode/darkmode";

export interface LogoProps {
    img?: string;
    imgLight?: string;
}

const Logo: React.FC<LogoProps> = ({img = logoDark, imgLight = logoLightImg}) => {

    const darkmodeState = useAppSelector(selectDarkmodeState);
    img = darkmodeState ? imgLight : img;
    return (
        <Link to="/" className="ttnc-logo inline-block text-primary-6000">
            <img style={{width: 270}} src={img} title={"Dinle Beni"} alt={"Dinle Beni"}/>
        </Link>
    );
};

export default Logo;
