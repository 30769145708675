import React from "react";
import MyRouter from "routers/index";
import ReactGA from "react-ga4";

function App() {
    return (
        <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
            <MyRouter/>
        </div>
    );
}

ReactGA.send({hitType: "pageview", page: window.location.pathname});

export default App;
